import React from "react";
import CONTACATEZ_MOI from "../components/Contact/contact.jsx";

const Contact = () => {
  return (
    <>
      <div className="espace-contact"></div>
      <CONTACATEZ_MOI />

    </>
  );
};

export default Contact;
