import React from "react";
import Projets from "../components/Projects/projets";

const Realisation = () => {
  return (
    <>
      <div className="mt"></div>
      <Projets
        butProjet={false}
        titreSectionProjet={false}
        projetDemo={true}
        showAll={true}
      />
    </>
  );
};

export default Realisation;
