import React, { useState } from "react";
import "./Footer.css";

const Footer = () => {
  const [accordion, setAccordion] = useState(false);

  const Accordion = () => {
    setAccordion(!accordion);
  };

  return (
      <footer className="monfooter">
        {/* <div className="resaux">
          <ul>
            <li>
              <a
                href="https://linkedin.com/in/mohamed-eleisawy-1440b226b"
                target="_blank"
                aria-label="Profil LinkedIn de Mohamed Eleisawy"
                rel="noreferrer"
              >
                <i className="fa-brands mesResaux fa-linkedin linkedin" />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/MohamedEleisawy"
                target="_blank"
                aria-label="Profil GitHub de Mohamed Eleisawy"
                rel="noreferrer"
              >
                <i className="fa-brands mesResaux fa-github git" />
              </a>
            </li>
          </ul>
        </div> */}
        <div id="mentionLegal">
          <p>
            2024 - &copy; Mohamed Eleisawy - Tous droits réservés - { " "}
            <button
              type="button"
              aria-label="Ouverture accordéon : pour l'affichage des mentions légales"
              id="ouvertureDesMentionsLegales"
              onClick={Accordion}
              aria-expanded={accordion}
            >
               Mentions légales
            </button>
          </p>
          <article id="mention">
            <ul
              style={{ display: accordion ? "flex" : "none" }}
              id="accordion"
            >
              <li>
                <h2 className="Presentation_du_site accordion-title">
                  Présentation du site internet.
                </h2>
                <ul>
                  <li>
                    <p className="responsable-publication mentions">
                      Responsable de la publication : Eleisawy Mohamed –
                      eleisawy19@gmail.com
                    </p>
                  </li>
                  <li>
                    <p className="hebergeur mentions">
                      Hébergeur : O2Switch – Chem. des Pardiaux 63000
                      Clermont-Ferrand 04 44 44 60 40
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="accordion-title">
                  Texte droits d'accès Mentions légales
                </h2>
                <ul>
                  <li>
                    <p className="mentions">
                      Le site mohamedeleisawy.com ne conserve, ne stocke aucune
                      donnée personnelle concernant ses visiteurs.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="propiete_inellectuelle accordion-title">
                  Propriété intellectuelle et contrefaçons.
                </h2>
                <ul>
                  <li>
                    <p className="mentions">
                      <a href="https://mohamedeleisawy.com">
                        https://mohamedeleisawy.com
                      </a>{" "}
                      est propriétaire des droits de propriété intellectuelle et
                      détient les droits d'usage sur tous les éléments
                      accessibles sur le site internet, notamment les textes,
                      images, graphismes, logos, vidéos, icônes et sons.
                    </p>
                    <p className="mentions">
                      Toute reproduction, représentation, modification,
                      publication, adaptation de tout ou partie des éléments du
                      site, quel que soit le moyen ou le procédé utilisé, est
                      interdite, sauf autorisation écrite préalable de :{" "}
                      <a href="https://mohamedeleisawy.com">
                        https://mohamedeleisawy.com
                      </a>
                    </p>
                    <p className="mentions">
                      Toute exploitation non autorisée du site ou de l'un
                      quelconque des éléments qu'il contient sera considérée
                      comme constitutive d'une contrefaçon et poursuivie
                      conformément aux dispositions des articles L.335-2 et
                      suivants du Code de Propriété Intellectuelle.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="accordion-title">Droit et Images</h2>
                <ul>
                  <li>
                    <p className="mentions">
                      Toutes les images utilisées sont libres de droit et
                      proviennent de Pexels, Unsplash et Pixabay.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </article>
        </div>
      </footer>
  );
};

export default Footer;