import { Link } from "react-router-dom";
import Header from "../components/Header/header";
import QuiSuisJe from "../components/Presentation/quiSuisJe";
import Projets from "../components/Projects/projets";
import Service from "../components/Services/service";
import Contact from "../components/Contact/contact";
const Home = () => {
  console.log("Home");  
  return (
    <>
      <Header />
      {/* methode 1 d'insretion  */}
      <QuiSuisJe
        debutPresentaion={true}
        lienEnSavoirPlus={
          <p className="savoir-plus">
            <Link to="presentation">En savoir plus</Link>
            <i className="fa-solid fa-arrow-right fleche-droite"></i>
          </p>
        }
      />
      {/* methode 2 d'insertion */}
      <Projets
        butProjet={true}
        titreSectionProjet={true}
        projetDemo={true}
        showAll={false}
      />
      <Service />
      <Contact />
    </>
  );
};

export default Home;
