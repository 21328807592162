import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Nav.css";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fermerMenu = () => {
    setMenuOpen(false);
  };
  return (
    <>
      <div id="hamburger" onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </div>

      <nav id="menu" className={`bare ${menuOpen ? "show" : ""}`}>
        <div className="logo">
          <Link to="https://mohamedeleisawy.com/">
            <img
              src="https://i.postimg.cc/cgpVTszS/logonoirsansbaseline.webp"
              alt="Logotype de Mohamed Eleisawy en noir"
              loading="lazy"
              width={100}
              height={100}
              title="Logotype de Mohamed Eleisawy en noir"
            />
          </Link>
        </div>
        <ul>
          <li>
            <NavLink to="/" className="element-menu element" title="Accueil" onClick={fermerMenu}>
              Accueil
            </NavLink>
          </li>
          <li>
            <NavLink to="/Presentation" title="À propos" onClick={fermerMenu}>
              Qui suis-je ?
            </NavLink>
          </li>
          <li>
            <NavLink to="/Realisation" title="Projets" onClick={fermerMenu}>
              Réalisations
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/Dashboard" title="dashboard">
              Dash
            </NavLink>
          </li> */}
        </ul>
        <div className="contactez">
          <NavLink to="/contact" title="Contact" className="contact-link" onClick={fermerMenu}>
            <span className="contact-dot">•</span>
            <span className="contactez-moi">Contactez-moi </span>
            <i className="fa-solid fa-arrow-right fleche-droite"></i>
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default Nav;
