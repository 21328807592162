import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Mohamed from "../../img/momo.webp";
import LogoReact from "../../img/raect.png";
// import LogoCakePHP from "../img/cakephp.svg";
import mortarboard from "../../img/mortarboard.webp";
import LogoNode from "../../img/nodejs-icon.svg";

const Header = () => {
  return (
    <main>
      <header>
        <div className="header-content">
          <div className="presBref">
            <h1>
              Mohamed Eleisawy,
              <span>
                développeur Web.
                <svg className="etoile" viewBox="0 0 2000 2000">
                  <g>
                    <g
                      fill="hsl(0, 0%, 0%)"
                      id="star"
                      transform="matrix(0.32556815445715676,0.9455185755993167,-0.9455185755993167,0.32556815445715676,1619.95042114216,-296.08673005647347)"
                    >
                      <path
                        d="M 500 500 C 1000 1000 1000 1000 1500 500 C 1000 1000 1000 1000 1500 1500 C 1000 1000 1000 1000 500 1500 C 1000 1000 1000 1000 500 500"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <svg
                id="Calque_3"
                className="signature"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 725.54 214.25"
              >
                <defs>
                  <style>
                    {`.cls-1 {
              fill: none;
              stroke: #13e9c1;
              stroke-width: 23px;
            }`}
                  </style>
                </defs>
                <g id="Calque_3-4" data-name="Calque_2">
                  <path
                    className="cls-1"
                    d="M7.68,147.5c202-181.33,268.67-181.33,200,0-71.31,188.32,576.97-180.1,500,0"
                  />
                </g>
              </svg>
            </h1>
            <p>
              Bienvenue sur mon site web personnel. Je suis un développeur web
              passionné par la création de sites web et d'applications web
              modernes.
            </p>
            <Link to="/contact" title="Contact">
              <button>Contactez-moi ! </button>
            </Link>
          </div>
          <div className="image-container">
            <div class="blur">
              <div class="css-blurry-gradient">1</div>
            </div>
            <div className="cercle"></div>
            <div className="cercle2"></div>
            <img
              className="mohamed1"
              src={Mohamed}
              alt="Mohamed"
              width={400}
              height={450}
            />
            <img
              className="logo-react tada"
              src={LogoReact}
              alt="logo react"
              width={65}
              height={65}
            />
            <img
              className="logo-cakePHP tada"
              src={LogoNode}
              alt="logo cakephp"
              width={45}
              height={45}
            />

            {/* <img className="logo-cakePHP" src={LogoPHP} alt="logo react" width={40} height={40} /> */}
            <div className="experiance">
              <h3 className="annee-experiance">2 Ans</h3>
              <h3 className="experiances">d'expériences</h3>
            </div>
            <div className="certification">
              <h3 className="nom-certification">
                {" "}
                <span>
                  <img
                    src={mortarboard}
                    width={35}
                    height={35}
                    alt="certification"
                    className="tada"
                  />
                </span>{" "}
                OpenClassrooms
              </h3>
              <h3 className="techno-certification">ReactJS</h3>
            </div>
          </div>
        </div>
      </header>
    </main>
  );
};

export default Header;
