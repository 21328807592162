// src/index.js
import React from 'react';

import Home from './pages/home';
import Presentation from './pages/presentation';
import Realisation from './pages/realisation';
import Contact from './pages/contact';
import Dashboard from './pages/dashboard';
import RootPages from './pages/rootPages';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPages />,
    errorElement: <h1>Erreur 404 ;</h1>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/presentation",
        element: <Presentation />,
      },
      {
        path: "/realisation",
        element: <Realisation />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
   
    ],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
]);


const App = () => {
  return (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  );
}

export default App;



 
