import React, { useState, useEffect } from "react";

const Dashboard = () => {
  const [donnees, setDonnees] = useState([]);

  const fetchDonnes = () => {
    fetch("/data/form") // URL de l'API
      .then((res) => res.json()) // Convertit la réponse en JSON
      .then((data) => setDonnees(data)) // Met à jour le state avec les données
      .catch((err) => console.error("Error fetching data:", err)); // Affiche une erreur si la requête échoue
  };
  useEffect(() => {
    fetchDonnes();
  }, []);

  const supprimerContact = (id) => {
   let questionConfirm = window.confirm('Voulez-vous vraiment supprimer ce contact ?');
    if (questionConfirm) {
    fetch(`/data/form/${id}`, {
      method: "DELETE",
    })

    .then((res) => {
      if (res.ok) {
        fetchDonnes();
      } else {
        console.error("Error deleting contact");
      }
    })
    .catch((err) => console.error("Error deleting contact:", err));
  }
  else {
    console.log("Suppression annulée");
  }
  };



  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("fr-FR", options); // Format jj/mm/aaaa hh:mm
  };

  return (
    <div style={styles.dashboard}>
      <h1 style={styles.title}>Dashboard</h1>
      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Nom</th>
              <th style={styles.th}>Prénom</th>
              <th style={styles.th}>Email</th>
              <th style={styles.th}>Message</th>
              <th style={styles.th}>Date d'envoi</th>
              <th style={styles.th}>
                <i className="fa-solid fa-trash-can"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Affiche un message si aucun contact n'est trouvé */}
            {donnees.length > 0 ? (
              donnees.map((contact, index) => (
                <tr key={index}>
                  <td style={styles.td}>{contact.nom}</td>
                  <td style={styles.td}>{contact.prenom}</td>
                  <td style={styles.td}>{contact.email}</td>
                  <td style={styles.td}>{contact.message}</td>
                  <td style={styles.td}>{formatDate(contact.date_envoi)}</td>
                  <td style={styles.td}>

                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => supprimerContact(contact._id)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={styles.noData}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  dashboard: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "100%",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    color: "orange",
  },
  tableContainer: {
    overflowX: "auto", // Permet le défilement horizontal si nécessaire
    width: "100%",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    textAlign: "left",
    color: "blue",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
  noData: {
    textAlign: "center",
    padding: "20px",
    color: "#999",
  },

  // Styles pour les petits écrans (mobile-first)
  "@media (min-width: 480px)": {
    th: {
      fontSize: "14px", // Ajuste la taille de la police pour les petits écrans
      padding: "10px",
    },
    td: {
      fontSize: "14px",
      padding: "10px",
    },
  },
  "@media (min-width: 768px)": {
    th: {
      fontSize: "16px", // Ajuste la taille de la police pour les écrans moyens
      padding: "12px",
    },
    td: {
      fontSize: "16px",
      padding: "12px",
    },
  },
  "@media (min-width: 1024px)": {
    th: {
      fontSize: "18px", // Ajuste la taille de la police pour les grands écrans
      padding: "14px",
    },
    td: {
      fontSize: "18px",
      padding: "14px",
    },
  },
};

export default Dashboard;
