import React from "react";
import LeDashboard from "../components/Admin/Dashboard/dashboard";
import Nav from "../components/Nav/nav";

const Dashboard = () => {
    return (

        <div>
            <Nav />
    <div className="mt"></div>
            <LeDashboard />
        </div>
    );
    }

    export default Dashboard;
