import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Projets.css";
import projet_1 from "../../img/AlgoScope-Logo.webp";
import projet_2 from "../../img/cimw.webp";
import LogoCakePHP from "../../img/cakephp.svg";
import projet_3 from "../../img/projet-blague.jpg";
import projet_4 from "../../img/portfolio1.webp";
import projet_5 from "../../img/projet-Image.jpg";
import projet_6 from "../../img/miniSubway.png";
import projet_7 from "../../img//jeu-XO.png";

const Projets = ({ butProjet, titreSectionProjet, projetDemo, showAll }) => {
  const [afficherPlus, setAfficherPlus] = useState(false);

  useEffect(() => {
    const customCarousel = document.querySelector(".custom-carousel");

    const toggleActive = (event) => {
      const items = customCarousel.querySelectorAll(".item");
      items.forEach((item) => {
        item.classList.remove("projet-actif");
      });
      event.target.classList.toggle("projet-actif");
    };

    if (customCarousel) {
      customCarousel.addEventListener("click", (e) => {
        if (e.target.classList.contains("item")) {
          toggleActive(e);
        }
      });
    }
  }, []);

  const projects = [
    {
      id: 1,
      backgroundImage: projet_1,
      title: "Site Algoscope",
      description:
        "Lors de mon stage, j'ai transformé le site Algoscope de WordPress en une version statique en HTML, CSS et JavaScript. J'ai ensuite créé une nouvelle version du site.",
      link: "https://algoscope.io/",
    },
    {
      id: 2,
      backgroundImage: projet_2,
      title: "Application CIMW",
      description:
        "Réalisation d'une mini-application de démonstration de chartes graphiques pour la commercialisation de solution web de gestion avec le framework CakePHP",
      link: "http://www.showroomcimw.fr",
    },
    {
      id: 3,
      backgroundImage: projet_3,
      title: "Générateur blagues",
      description:
        "J'adore rire et partager de l'humour. Pour ne jamais oublier mes blagues hilarantes, j'ai créé un générateur de blagues personnel.",
      github: "https://github.com/MohamedEleisawy/Blague",
      link: "https://projet-blague.000webhostapp.com/",
    },
    {
      id: 4,
      backgroundImage: projet_4,
      title: "Version 1 Portfolio",
      description:
        "Réalisation de mon premier portfolio dynamique avec PHP et une base de données MySQL. En integrant phpmailer pour la gestion des mails et un formulaire de contact sécurisé avec reCaptcha. Les données du formulaire sont stockées dans une base de données MySQL. La partie présentaion est modifiable via un back-office.",
      link: "https://mohamedeleisawy.com/ancienPortfolio/",
    },
    {
      id: 5,
      backgroundImage: projet_5,
      title: "Gestionnaire images",
      description:
        "Réalisation de mon premier portfolio dynamique avec PHP et une base de données MySQL.",
      github: "https://github.com/MohamedEleisawy/Projet_Gestion_Images",
      link: "https://projet-gestion-images.alwaysdata.net/",
    },
    {
      id: 6,
      backgroundImage: projet_6,
      title: "Mini-Subway",
      description:
        "Mini-Subawy est un jeu que j'ai réalisé en utilisant HTML, CSS et JavaScript. Le jeu consiste à déplacer un bloc pour éviter des obstacles.",
      github: "https://github.com/MohamedEleisawy/Projet_Mini-Subway",
      link: "https://mohamedeleisawy.github.io/Projet_Mini-Subway/",
    },
    {
      id: 7,
      backgroundImage: projet_7,
      title: "Jeu XO",
      description:
        "Jeu XO est un jeu que j'ai réalisé en utilisant HTML, CSS et JavaScript. Le jeu consiste à aligner 3 symboles pour gagner.",
      github: "https://github.com/MohamedEleisawy/Jeu-XO",
      link: "https://mohamedeleisawy.github.io/Jeu-XO/",
    },
  ];

  const projectsToDisplay = showAll ? projects : projects.slice(0, 3);

  return (
    <>
      <section className="projets">
        {titreSectionProjet && (
          <div className="nom-section-projet">
            <p>Mes Derniers Projets</p>
            <p>03</p>
          </div>
        )}
        {projetDemo && (
          <div className="projet-section">
            <div className="custom-carousel owl-theme">
              {projectsToDisplay.map((project, index) => (
                <div
                  key={project.id}
                  className={`item ${index === 0 ? "projet-actif" : ""}`}
                  style={{
                    backgroundImage: `url(${project.backgroundImage})`,
                  }}
                >
                  <div className="item-desc">
                    <h4 className="h3">{project.title}</h4>
                    <p>
                      {project.description}
                      {index === 0 && (
                        <>
                          <button
                            id="lirePlus"
                            onClick={() => setAfficherPlus(true)}
                            style={{ display: afficherPlus ? "none" : "block" }}
                          >
                            Lire plus
                          </button>
                          <div
                            id="plus"
                            style={{ display: afficherPlus ? "block" : "none" }}
                          >
                            à l'aide d'une maquette fournie pour améliorer la
                            visibilité du site.
                            <button
                              id="lireMoins"
                              onClick={() => setAfficherPlus(false)}
                              style={{
                                display: afficherPlus ? "block" : "none",
                              }}
                            >
                              Afficher moins
                            </button>
                          </div>
                        </>
                      )}
                    </p>
                    <div className="competanacesEtVisualisationProjets">
                      {index === 0 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 1 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <img
                              className="logoCakeProjet"
                              src={LogoCakePHP}
                              alt="Logo CakePHP"
                            />
                            <abbr title="Hypertext Preprocessor">CAKEPHP</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 2 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <i
                              className="fa-brands logoCompetences fa-php"
                              id="logo-php"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Hypertext Preprocessor">PHP</abbr>
                          </li>
                          <li aria-labelledby="logo-sql">
                            <i
                              className="fa-solid logoCompetences fa-database"
                              id="logo-sql"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Structured Query Language">SQL</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 3 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <i
                              className="fa-brands logoCompetences fa-php"
                              id="logo-php"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Hypertext Preprocessor">PHP</abbr>
                          </li>
                          <li aria-labelledby="logo-sql">
                            <i
                              className="fa-solid logoCompetences fa-database"
                              id="logo-sql"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Structured Query Language">SQL</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 4 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <i
                              className="fa-brands logoCompetences fa-php"
                              id="logo-php"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Hypertext Preprocessor">PHP</abbr>
                          </li>
                          <li aria-labelledby="logo-sql">
                            <i
                              className="fa-solid logoCompetences fa-database"
                              id="logo-sql"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Structured Query Language">SQL</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 5 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 6 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                        </ul>
                      )}
                      <ul className="voirProjet">
                        {project.github && (
                          <li>
                            <Link
                              to={project.github}
                              target="blank"
                              aria-label="Voir le projet sur Github"
                              className=""
                            >
                              <i className="fa-brands fa-github"></i>
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link
                            to={project.link}
                            target="blank"
                            aria-label="Voir le projet"
                            className=""
                          >
                            <i
                              className="fa-solid fa-eye"
                              style={{ color: "#00ae27" }}
                            ></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
      {butProjet && (
        <section className="but-projet">
          <h2>
            En tant que <span>développeur web</span> j'aide les entreprises
            partout en France à augmenter leur trafic grâce à un{" "}
            <span>site web</span> référencé et de haute qualité.
          </h2>
          <div className="svg-signature">
      <svg
        id="Calque_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 725.54 214.25"
      >
        <defs>
          <style>
            {`.cls-1 {
                fill: none;
                stroke: #13e9c1;
                stroke-width: 23px;
              }`}
          </style>
        </defs>
        <g id="Calque_1-2" data-name="Calque_1">
          <path
            className="cls-1"
            d="M7.68,147.5c202-181.33,268.67-181.33,200,0-71.31,188.32,576.97-180.1,500,0"
          />
        </g>
      </svg>
    </div>

          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 800 800"
          >
            <defs>
              <radialGradient
                id="ccclaymoji-grad-dark"
                r="93%"
                cx="20%"
                cy="20%"
              >
                <stop
                  offset="70%"
                  stop-color="hsl(115, 100%, 84%)"
                  stop-opacity="0"
                ></stop>
                <stop offset="97%" stop-color="#82cb7d" stop-opacity="1"></stop>
              </radialGradient>
              <radialGradient
                id="ccclaymoji-grad-light"
                r="65%"
                cx="28%"
                cy="20%"
              >
                <stop
                  offset="0%"
                  stop-color="#e8ffdf"
                  stop-opacity="0.75"
                ></stop>
                <stop
                  offset="100%"
                  stop-color="hsl(115, 100%, 84%)"
                  stop-opacity="0"
                ></stop>
              </radialGradient>
              <filter
                id="ccclaymoji-blur"
                x="-100%"
                y="-100%"
                width="400%"
                height="400%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feGaussianBlur
                  stdDeviation="72"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  in="SourceGraphic"
                  edgeMode="none"
                  result="blur"
                ></feGaussianBlur>
              </filter>
              <filter
                id="inner-blur"
                x="-100%"
                y="-100%"
                width="400%"
                height="400%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feGaussianBlur
                  stdDeviation="2"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  in="SourceGraphic"
                  edgeMode="none"
                  result="blur"
                ></feGaussianBlur>
              </filter>
              <filter
                id="eye-shadow"
                x="-100%"
                y="-100%"
                width="400%"
                height="400%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feDropShadow
                  stdDeviation="10"
                  dx="10"
                  dy="10"
                  flood-color="#000000"
                  flood-opacity="0.2"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  result="dropShadow"
                ></feDropShadow>
              </filter>
              <linearGradient
                gradientTransform="rotate(-25)"
                id="eye-light"
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
              >
                <stop offset="20%" stop-color="#555555" stop-opacity="1"></stop>
                <stop offset="100%" stop-color="black" stop-opacity="0"></stop>
              </linearGradient>
              <linearGradient
                id="mouth-light"
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
              >
                <stop offset="0%" stop-color="#ff9667" stop-opacity="1"></stop>
                <stop
                  offset="100%"
                  stop-color="hsl(3, 100%, 51%)"
                  stop-opacity="0"
                ></stop>
              </linearGradient>
              <filter
                id="mouth-shadow"
                x="-100%"
                y="-100%"
                width="400%"
                height="400%"
                filterUnits="objectBoundingBox"
                primitiveUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feDropShadow
                  stdDeviation="10"
                  dx="10"
                  dy="10"
                  flood-color="#c20000"
                  flood-opacity="0.2"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  result="dropShadow"
                ></feDropShadow>
              </filter>
            </defs>
            <g stroke-linecap="round" transform="rotate(15, 400, 400)">
              <path
                d="M645.5 458.99999835484755C645.5 582.8961573844972 566.8961590296497 683.3328824518826 443 683.3328824518826C319.10438679245283 683.3328824518826 240.5 582.8961573844972 240.5 458.99999835484755C240.5 335.1043851473004 319.10438679245283 234.6671142578125 443 234.6671142578125C566.8961590296497 234.6671142578125 645.5 335.1043851473004 645.5 458.99999835484755Z "
                fill="#82cb7d"
                opacity="0.31"
                filter="url(#ccclaymoji-blur)"
              ></path>
              <path
                d="M602.5 399.99999835484755C602.5 523.8961573844972 523.8961590296497 624.3328824518826 400 624.3328824518826C276.10438679245283 624.3328824518826 197.5 523.8961573844972 197.5 399.99999835484755C197.5 276.1043851473004 276.10438679245283 175.6671142578125 400 175.6671142578125C523.8961590296497 175.6671142578125 602.5 276.1043851473004 602.5 399.99999835484755Z "
                fill="hsl(115, 100%, 84%)"
              ></path>
              <path
                d="M602.5 399.99999835484755C602.5 523.8961573844972 523.8961590296497 624.3328824518826 400 624.3328824518826C276.10438679245283 624.3328824518826 197.5 523.8961573844972 197.5 399.99999835484755C197.5 276.1043851473004 276.10438679245283 175.6671142578125 400 175.6671142578125C523.8961590296497 175.6671142578125 602.5 276.1043851473004 602.5 399.99999835484755Z "
                fill="url(#ccclaymoji-grad-dark)"
              ></path>
              <path
                d="M602.5 399.99999835484755C602.5 523.8961573844972 523.8961590296497 624.3328824518826 400 624.3328824518826C276.10438679245283 624.3328824518826 197.5 523.8961573844972 197.5 399.99999835484755C197.5 276.1043851473004 276.10438679245283 175.6671142578125 400 175.6671142578125C523.8961590296497 175.6671142578125 602.5 276.1043851473004 602.5 399.99999835484755Z "
                fill="url(#ccclaymoji-grad-light)"
              ></path>
              <ellipse
                rx="21"
                ry="21.5"
                cx="353"
                cy="361"
                fill="black"
                filter="url(#eye-shadow)"
              ></ellipse>
              <ellipse
                rx="21"
                ry="21.5"
                cx="353"
                cy="361"
                fill="url(#eye-light)"
                filter="url(#inner-blur)"
              ></ellipse>
              <ellipse
                rx="22.5"
                ry="21.5"
                cx="489"
                cy="355"
                fill="black"
                filter="url(#eye-shadow)"
              ></ellipse>
              <ellipse
                rx="22.5"
                ry="21.5"
                cx="489"
                cy="355"
                fill="url(#eye-light)"
                filter="url(#inner-blur)"
              ></ellipse>
              <path
                d="M377 501Q415 541 459 501 "
                stroke-width="21"
                stroke="hsl(3, 100%, 51%)"
                fill="none"
                filter="url(#mouth-shadow)"
              ></path>
              <path
                d="M377 501Q415 541 459 501 "
                stroke-width="7"
                stroke="url(#mouth-light)"
                fill="none"
                filter="url(#inner-blur)"
              ></path>
            </g>
          </svg> */}
          <hr className="fin-section-projets" />
        </section>
      )}
    </>
  );
};

export default Projets;
