import React from 'react';
import Nav from "../components/Nav/nav";
import Footer from "../components/Footer/footer";
import { Outlet } from 'react-router-dom';
import ScrollToTop from '../components/scroll/scrollTop';

const RootPages = () => {
    return (
        <>
            <ScrollToTop /> 
            <Nav />
            <main>
            <ScrollToTop /> 
            <Outlet />

            </main>
            <Footer />
        </>
    );
}

export default RootPages;
